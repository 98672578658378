<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <div class="drawer-bar auto">
        <div class="fl">
          <span class="font16"><b>系统概况</b></span>
        </div>
      </div>
      <div class="department-content auto">
        <div class="box auto">
          <div class="box-title auto">
            <div class="fl">系统当前总体情况</div>
          </div>
          <a-spin :spinning="chartLoading1">
            <div class="box-con auto">
              <div style="width:300px;height:180px;" class="fl">
                <echarts-base :data="chartData1" :height="180"></echarts-base>
              </div>
              <div style="width:300px;height:180px;" class="fl">
                <echarts-base :data="chartData2" :height="180"></echarts-base>
              </div>
              <div style="width:300px;height:180px;" class="fl">
                <echarts-base :data="chartData3" :height="180"></echarts-base>
              </div>
            </div>
          </a-spin>
        </div>
        <div class="box box2 auto">
          <div class="box-title auto">
            <div class="fl">历史注册人数</div>
            <div class="fr">
              <custom-date-picker v-model="date" @getDate="getDate" @getMode="getMode"></custom-date-picker>
            </div>
          </div>
          <div class="box-con auto no-bor">
            <div style="width:100%;height:300px;">
              <a-spin :spinning="chartLoading4">
                <echarts-base :data="chartData4" :height="300"></echarts-base>
              </a-spin>
            </div>
          </div>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { System } from '@/model'
import { Data as DataController } from '@/controller'
export default {
  data () {
    return {
      date: [],
      chartLoading1: false,
      chartData1: '',
      chartData2: '',
      chartData3: '',
      chartData4: '',
      filter: {
        mode: 'week',
        startDate: '',
        endDate: ''
      },
      chartLoading4: false
    }
  },
  methods: {
    getMode (mode) {
      this.filter.mode = mode
    },
    getDate (date) {
      // console.log('date', date)
      this.filter.startDate = date[0]
      this.filter.endDate = date[1]
      this.getGeneralSummaryRegistered()
    },
    async getGeneralSummary () {
      this.chartLoading1 = true
      let res = await System.getGeneralSummary()
      this.chartLoading1 = false
      if (!res['code']) {
        let chart1 = {
          '已注册': res['data']['registered'],
          '未注册': res['data']['unregistered']
        }
        this.chartData1 = DataController.System.Pie1(chart1)
        let chart2 = {
          '正常': res['data']['permit'],
          '停用': res['data']['prohibit'],
          '即将停用': res['data']['limittime']
        }
        this.chartData2 = DataController.System.Pie2(chart2)
        let chart3 = {
          '未锁定': res['data']['unlocked'],
          '管理员锁定': res['data']['lockedofmanagement'],
          '长期闲置锁定': res['data']['lockedofunused'],
          '破解风险锁定': res['data']['lockedofhazard'],
        }
        this.chartData3 = DataController.System.Pie3(chart3)
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getGeneralSummaryRegistered () {
      this.chartLoading4 = true
      let res = await System.getGeneralSummaryRegistered(this.filter)
      this.chartLoading4 = false
      if (!res['code']) {
        this.chartData4 = DataController.System.Bar1(res['data'].length ? res['data'] : '')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    this.getGeneralSummary()
    // this.getGeneralSummaryRegistered()
  },
  mounted () {
    // this.chartData2 =  {
    //     tooltip: {
    //         trigger: 'item'
    //     },
    //     grid: {
    //       x: '50',
    //       y: '15%',
    //       x2: '0%',
    //       y2: '10%'
    //     },
    //     xAxis: {
    //         type: 'category',
    //         data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    //         axisTick: {
    //             show: false
    //         },
    //         axisLine: {
    //             show: false
    //         }
    //     },
    //     yAxis: {
    //         type: 'value'
    //     },
    //     series: [{
    //         name: 'Direct',
    //         barMaxWidth: 20,
    //         type: 'bar',
    //         stack: 'total',
    //         emphasis: {
    //             focus: 'series'
    //         },
    //         data: [320, 302, 301, 334, 390, 330, 320]
    //     }]
    // }
  }
};
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 20px;
}

.box-title .fl {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
}
.box-con {
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.box2 {
  margin-top: 20px;
}
.no-bor {
  border: none;
}
</style>
